import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";

function ReporteProgramacion() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [inventarios, setInventarios] = useState([]);
  const [tipos_telas, setTiposTelas] = useState([]);
  const [selectedTiposTelasFiltro, setSelectedTiposTelasFiltro] = useState("");
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [articulos, setArticulos] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedComposicion, setSelectedComposicion] = useState("");
  const [articulosComposicion, setArticulosComposicion] = useState([]);

  const [unidad, setUnidad] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [articulosCodigos, setArticulosCodigos] = useState([]);
  const [articulosNombre, setArticulosNombre] = useState("");
  const [selectedCodigo, setSelectedCodigo] = useState("");
  const [selectedNombre, setSelectedNombre] = useState("");

  const [articuloDesgloce, setArticuloDesgloce] = useState("");

  const [desglocePedidos, setDesglocePedidos] = useState([]);
  const [modalDesglocePedidos, setModalDesglocePedidos] = useState(false);
  const toggleDesglocePedidos = () => setModalDesglocePedidos(!modalDesglocePedidos);

  const [desgloceColores, setDesgloceColores] = useState([]);
  const [modalDesgloceColores, setModalDesgloceColores] = useState(false);
  const toggleDesgloceColores = () => setModalDesgloceColores(!modalDesgloceColores);


  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);
  const [articulosNota, setArticulosNota] = useState([]);
  const [modalDesgloceArticulosPedidos, setModalDesgloceArticulosPedidos] = useState(false);
  const toggleDesgloceArticulosPedidos = () => setModalDesgloceArticulosPedidos(!modalDesgloceArticulosPedidos);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_INVENTARIOS}ReporteProgramacion`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        let arrayDisponible = allDisponible
        .map((a)=>{
          if(a.cantidad > 0){
          return {
            articulo: a.articulo,
            idArticulo: a.idArticulo,
            colores: a.colores,
            idColores: a.idColores,
            cantidad: a.cantidad,
            recibido: a.recibido,
            pedido: a.pedido,
            noOrdenProveedor: a.noOrdenProveedor,
            noOrden: a.noOrden,
            idOrden: a.idOrden,
            proveedor: a.proveedor,
            idProveedor: a.idProveedor,
            tipoCambioEstimado: a.tipoCambioEstimado,
            fechaOC: a.fechaOC,
            fechaCompromiso: a.fechaCompromiso,
            fechaPuertoEst: a.fechaPuertoEst,
            fechaEntregaEst: a.fechaEntregaEst,
            precio: a.precio,

            disponible: a.cantidad - a.pedido,
            precioTotal: (a.cantidad * a.precio) * a.tipoCambioEstimado,
            precioDisponible: ((a.cantidad - a.pedido) * a.precio) * a.tipoCambioEstimado
          }
          }
        })
        .filter(function (el) {
          return el != null;
        });
        setComments(arrayDisponible);
        setInventarios(arrayDisponible);
        // let agrupadoNombres = allDisponible.reduce(function (groups, item) {
        //   const val = item["articulo"]
        //   groups[val] = groups[val] || {
        //     articulo: item.articulo,
        //     idArticulo:""
        //   };
        //   groups[val].articulo = item.articulo;
        //   groups[val].idArticulo = item.idArticulo;
        //   return groups;
        // }, []);
        // let dataFinalNombres = Object.values(agrupadoNombres);
        // setArticulosNombre(dataFinalNombres);

      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColores = response.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaInventarios = comments.sort((a, b) =>
    a.codigo > b.codigo ? 1 : -1
  );

  function PDFTablaDisponible() {
    let totalMetros = 0;
    let totalPedido = 0;
    let totalDisponible = 0;
    let totalPrecio = 0;
    let totalPrecioDisp = 0;

    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
        comment.noOrdenProveedor.toLowerCase().includes(search.toLowerCase()) ||
        comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
        comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaOC.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaCompromiso.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaPuertoEst.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaEntregaEst.toLowerCase().includes(search.toLowerCase()) ||
        comment.noOrden.toString().includes(search) ||
        comment.precioDisponible.toString().includes(search) ||
        comment.precioTotal.toString().includes(search) ||
        comment.tipoCambioEstimado.toString().includes(search) ||
        comment.precio.toString().includes(search) ||
        comment.disponible.toString().includes(search)||
        comment.pedido.toString().includes(search)||
        comment.cantidad.toString().includes(search)
    );
  }
    

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "noOrden" &&
      sorting.field != "precioDisponible" &&
      sorting.field != "precioTotal" &&
      sorting.field != "tipoCambioEstimado" &&
      sorting.field != "precio" &&
      sorting.field != "disponible" &&
      sorting.field != "pedido" &&
      sorting.field != "cantidad"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (
        sorting.field == "noOrden" ||
        sorting.field == "precioDisponible" ||
        sorting.field == "precioTotal" ||
        sorting.field == "tipoCambioEstimado" ||
        sorting.field == "precio" ||
        sorting.field == "disponible" ||
        sorting.field == "pedido" ||
        sorting.field == "cantidad"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (
        sorting.field == "noOrden" ||
        sorting.field == "precioDisponible" ||
        sorting.field == "precioTotal" ||
        sorting.field == "tipoCambioEstimado" ||
        sorting.field == "precio" ||
        sorting.field == "disponible" ||
        sorting.field == "pedido" ||
        sorting.field == "cantidad"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    const data = computedComments
      .map((a) => {
        totalMetros = totalMetros + a.cantidad;
        totalPedido = totalPedido + a.pedido;
        totalDisponible = totalDisponible + a.disponible;
        totalPrecio = totalPrecio + a.precioTotal
        totalPrecioDisp = totalPrecioDisp + a.precioDisponible
          return [
            a.noOrden,
            a.noOrdenProveedor,
            a.proveedor,
            a.articulo,
            new Intl.NumberFormat("en-US").format(a.disponible),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tipoCambioEstimado),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precioDisponible),
            a.fechaOC,
            a.fechaCompromiso,
            a.fechaPuertoEst,
            a.fechaEntregaEst
          ];
        }
      )
      
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Reporte Programacion Disponible`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Orden Prov",
          "Proveedor",
          "Articulo",
          "Disponible",
          "Precio USD",
          "TC",
          "Precio Disp.",
          "Fecha OC",
          "Fecha Emb.",
          "Fecha Puerto",
          "Fecha Entrega",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(totalDisponible),
          "",
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPrecioDisp),
          "",
          "",
          "",
          "",
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`ReporteProgramacionDisponible.pdf`);
  }

  function PDFTabla() {
    let totalMetros = 0;
    let totalPedido = 0;
    let totalDisponible = 0;
    let totalPrecio = 0;
    let totalPrecioDisp = 0;

    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
        comment.noOrdenProveedor.toLowerCase().includes(search.toLowerCase()) ||
        comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
        comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaOC.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaCompromiso.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaPuertoEst.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaEntregaEst.toLowerCase().includes(search.toLowerCase()) ||
        comment.noOrden.toString().includes(search) ||
        comment.precioDisponible.toString().includes(search) ||
        comment.precioTotal.toString().includes(search) ||
        comment.tipoCambioEstimado.toString().includes(search) ||
        comment.precio.toString().includes(search) ||
        comment.disponible.toString().includes(search)||
        comment.pedido.toString().includes(search)||
        comment.cantidad.toString().includes(search)
    );
  }
    

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "noOrden" &&
      sorting.field != "precioDisponible" &&
      sorting.field != "precioTotal" &&
      sorting.field != "tipoCambioEstimado" &&
      sorting.field != "precio" &&
      sorting.field != "disponible" &&
      sorting.field != "pedido" &&
      sorting.field != "cantidad"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (
        sorting.field == "noOrden" ||
        sorting.field == "precioDisponible" ||
        sorting.field == "precioTotal" ||
        sorting.field == "tipoCambioEstimado" ||
        sorting.field == "precio" ||
        sorting.field == "disponible" ||
        sorting.field == "pedido" ||
        sorting.field == "cantidad"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (
        sorting.field == "noOrden" ||
        sorting.field == "precioDisponible" ||
        sorting.field == "precioTotal" ||
        sorting.field == "tipoCambioEstimado" ||
        sorting.field == "precio" ||
        sorting.field == "disponible" ||
        sorting.field == "pedido" ||
        sorting.field == "cantidad"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    const data = computedComments
      .map((a) => {
        totalMetros = totalMetros + a.cantidad;
        totalPedido = totalPedido + a.pedido;
        totalDisponible = totalDisponible + a.disponible;
        totalPrecio = totalPrecio + a.precioTotal
        totalPrecioDisp = totalPrecioDisp + a.precioDisponible
          return [
            a.noOrden,
            a.noOrdenProveedor,
            a.proveedor,
            a.articulo,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.pedido),
            new Intl.NumberFormat("en-US").format(a.disponible),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tipoCambioEstimado),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precioTotal),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precioDisponible),
            a.fechaOC,
            a.fechaCompromiso,
            a.fechaPuertoEst,
            a.fechaEntregaEst
          ];
        }
      )
      
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Reporte Programacion`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Orden Prov",
          "Proveedor",
          "Articulo",
          "Metros OC",
          "Pedido",
          "Disponible",
          "Precio USD",
          "TC",
          "Precio Total",
          "Precio Disp.",
          "Fecha OC",
          "Fecha Emb.",
          "Fecha Puerto",
          "Fecha Entrega",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(totalMetros),
          new Intl.NumberFormat("en-US").format(totalPedido),
          new Intl.NumberFormat("en-US").format(totalDisponible),
          "",
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPrecio),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPrecioDisp),
          "",
          "",
          "",
          "",
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`ReporteProgramacion.pdf`);
  }

  function excel() {
    const dataExcel = comments
    .filter(
      (comment) =>
      comment.noOrdenProveedor.toLowerCase().includes(search.toLowerCase()) ||
      comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
      comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
      comment.fechaOC.toLowerCase().includes(search.toLowerCase()) ||
      comment.fechaCompromiso.toLowerCase().includes(search.toLowerCase()) ||
      comment.fechaPuertoEst.toLowerCase().includes(search.toLowerCase()) ||
      comment.fechaEntregaEst.toLowerCase().includes(search.toLowerCase()) ||
      comment.noOrden.toString().includes(search) ||
      comment.precioDisponible.toString().includes(search) ||
      comment.precioTotal.toString().includes(search) ||
      comment.tipoCambioEstimado.toString().includes(search) ||
      comment.precio.toString().includes(search) ||
      comment.disponible.toString().includes(search)||
      comment.pedido.toString().includes(search)||
      comment.cantidad.toString().includes(search)
    )
    .map((a) => {
        return {
          OrdenComp: a.noOrden,
          OrdenProv: a.noOrdenProveedor,
          Proveedor: a.proveedor,
          Articulo: a.articulo,
          Cantidad: a.cantidad,
          Pedido: a.pedido,
          Disponible: a.disponible,
          Precio: a.precio,
          TC: a.tipoCambioEstimado,
          PrecioTotal: a.precioTotal,
          PrecioDisponible: a.precioDisponible,
          fechaOC: a.fechaOC,
          fechaCompromiso: a.fechaCompromiso,
          fechaPuertoEst: a.fechaPuertoEst,
          fechaEntregaEst: a.fechaEntregaEst,
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ReporteProgramacion";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ReporteProgramacion",
        sheetFilter: [
          "OrdenProv",
          "Proveedor",
          "Articulo",
          "Cantidad",
          "Pedido",
          "Disponible",
          "Precio",
          "TC",
          "PrecioTotal",
          "PrecioDisponible",
          "fechaOC",
          "fechaCompromiso",
          "fechaPuertoEst",
          "fechaEntregaEst",
        ],
        sheetHeader: [
          "OrdenProv",
          "Proveedor",
          "Articulo",
          "Cantidad",
          "Pedido",
          "Disponible",
          "Precio",
          "TC",
          "PrecioTotal",
          "PrecioDisponible",
          "fechaOC",
          "fechaCompromiso",
          "fechaPuertoEst",
          "fechaEntregaEst",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let totalMetros = 0;
    let totalPedido = 0;
    let totalDisponible = 0;
    let totalPrecio = 0;
    let totalPrecioDisp = 0;

    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
        comment.noOrden.toLowerCase().includes(search.toLowerCase()) ||
        comment.noOrdenProveedor.toLowerCase().includes(search.toLowerCase()) ||
        comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
        comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaOC.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaCompromiso.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaPuertoEst.toLowerCase().includes(search.toLowerCase()) ||
        comment.fechaEntregaEst.toLowerCase().includes(search.toLowerCase()) ||
        comment.noOrrden.toString().includes(search) ||
        comment.precioDisponible.toString().includes(search) ||
        comment.precioTotal.toString().includes(search) ||
        comment.tipoCambioEstimado.toString().includes(search) ||
        comment.precio.toString().includes(search) ||
        comment.disponible.toString().includes(search)||
        comment.pedido.toString().includes(search)||
        comment.cantidad.toString().includes(search)
    );
  }
    

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "noOrden" &&
      sorting.field != "precioDisponible" &&
      sorting.field != "precioTotal" &&
      sorting.field != "tipoCambioEstimado" &&
      sorting.field != "precio" &&
      sorting.field != "disponible" &&
      sorting.field != "pedido" &&
      sorting.field != "cantidad"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (
        sorting.field == "noOrden" ||
        sorting.field == "precioDisponible" ||
        sorting.field == "precioTotal" ||
        sorting.field == "tipoCambioEstimado" ||
        sorting.field == "precio" ||
        sorting.field == "disponible" ||
        sorting.field == "pedido" ||
        sorting.field == "cantidad"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (
        sorting.field == "noOrden" ||
        sorting.field == "precioDisponible" ||
        sorting.field == "precioTotal" ||
        sorting.field == "tipoCambioEstimado" ||
        sorting.field == "precio" ||
        sorting.field == "disponible" ||
        sorting.field == "pedido" ||
        sorting.field == "cantidad"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    const data = computedComments
      .map((a) => {
        totalMetros = totalMetros + a.cantidad;
        totalPedido = totalPedido + a.pedido;
        totalDisponible = totalDisponible + a.disponible;
        totalPrecio = totalPrecio + a.precioTotal
        totalPrecioDisp = totalPrecioDisp + a.precioDisponible
          return [
            a.noOrden,
            a.noOrdenProveedor,
            a.proveedor,
            a.articulo,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.pedido),
            new Intl.NumberFormat("en-US").format(a.disponible),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tipoCambioEstimado),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precioTotal),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precioDisponible),
            a.fechaOC,
            a.fechaCompromiso,
            a.fechaPuertoEst,
            a.fechaEntregaEst
          ];
        }
      )
      
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Reporte Programacion`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Orden Prov",
          "Proveedor",
          "Articulo",
          "Metros OC",
          "Pedido",
          "Disponible",
          "Precio USD",
          "TC",
          "Precio Total",
          "Precio Disp.",
          "Fecha OC",
          "Fecha Emb.",
          "Fecha Puerto",
          "Fecha Entrega",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(totalMetros),
          new Intl.NumberFormat("en-US").format(totalPedido),
          new Intl.NumberFormat("en-US").format(totalDisponible),
          "",
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPrecio),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPrecioDisp),
          "",
          "",
          "",
          "",
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Reporte Programacion",
          email: mailTo,
          fileName: "ReporteProgramacion.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Disponibles.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Orden Comp", field: "noOrden", sortable: true },
    { name: "Orden Prov", field: "noOrdenProveedor", sortable: true },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "Articulo", field: "articulo", sortable: true },
    { name: "Metros OC", field: "cantidad", sortable: true },
    { name: "Pedido", field: "pedido", sortable: true },
    { name: "Disponible", field: "disponible", sortable: true },
    { name: "Precio USD", field: "precio", sortable: true },
    { name: "TC", field: "tipoCambioEstimado", sortable: true },
    { name: "Precio Total", field: "precioTotal", sortable: true },
    { name: "Precio Disponible", field: "precioDisponible", sortable: true },
    { name: "Fecha OC", field: "fechaOC", sortable: true },
    { name: "Fecha Emb. Aprox.", field: "fechaCompromiso", sortable: true },
    { name: "Fecha Puerto Est.", field: "fechaPuertoEst", sortable: true },
    { name: "Fecha Entrega Est.", field: "fechaEntregaEst", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.noOrdenProveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.fechaOC.toLowerCase().includes(search.toLowerCase()) ||
          comment.fechaCompromiso.toLowerCase().includes(search.toLowerCase()) ||
          comment.fechaPuertoEst.toLowerCase().includes(search.toLowerCase()) ||
          comment.fechaEntregaEst.toLowerCase().includes(search.toLowerCase()) ||
          comment.noOrden.toString().includes(search) ||
          comment.precioDisponible.toString().includes(search) ||
          comment.precioTotal.toString().includes(search) ||
          comment.tipoCambioEstimado.toString().includes(search) ||
          comment.precio.toString().includes(search) ||
          comment.disponible.toString().includes(search)||
          comment.pedido.toString().includes(search)||
          comment.cantidad.toString().includes(search)
      );
    }
    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "noOrden" &&
      sorting.field != "precioDisponible" &&
      sorting.field != "precioTotal" &&
      sorting.field != "tipoCambioEstimado" &&
      sorting.field != "precio" &&
      sorting.field != "disponible" &&
      sorting.field != "pedido" &&
      sorting.field != "cantidad"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (
        sorting.field == "noOrden" ||
        sorting.field == "precioDisponible" ||
        sorting.field == "precioTotal" ||
        sorting.field == "tipoCambioEstimado" ||
        sorting.field == "precio" ||
        sorting.field == "disponible" ||
        sorting.field == "pedido" ||
        sorting.field == "cantidad"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (
        sorting.field == "noOrden" ||
        sorting.field == "precioDisponible" ||
        sorting.field == "precioTotal" ||
        sorting.field == "tipoCambioEstimado" ||
        sorting.field == "precio" ||
        sorting.field == "disponible" ||
        sorting.field == "pedido" ||
        sorting.field == "cantidad"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedArticulo,
    selectedProveedor,
  ]);


  function desglocePedido(
    articulo,
    idArticulo,
    idOrden
  ) {
    setDesglocePedidos([])
    setArticulosNombre(articulo)
    axios
      .get(`${URL_ARTICULOS_PEDIDO}OCArticulos/${idArticulo}/${idOrden}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              articulo: a.articulo,
              idArticulo: a.idArticulo,
              color: a.color,
              idColor: a.idColor,
              cantidad: a.cantidad,
              pendiente_surtir: a.pendiente_surtir,
              pedido: a.pedido,
              cliente: a.cliente,
              fechaCompromiso: a.fechaCompromiso,
              observaciones: a.observaciones,
              idPedido: a.idPedido
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(allPedidos);
        setDesglocePedidos(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleDesglocePedidos();
  }

  function desgloceColor(
    articulo,
    idArticulo,
    idOrden
  ) {
    setArticulosNombre(articulo)
    setDesgloceColores([])

    axios
      .get(`${URL_INVENTARIOS}ReporteProgramacionArticulo/${idArticulo}/${idOrden}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColores = response.data
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              articulo: a.articulo,
              idArticulo: a.idArticulo,
              cantidad: a.cantidad,
              pedido: a.pedido,
              colores: a.colores,
              idColores: a.idColores,
              disponible: a.cantidad - a.pedido
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(allColores);
        setDesgloceColores(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleDesgloceColores();
  }

  function PDFTablaColores() {
    let totalMetros = 0;
    let totalPedido = 0;
    let totalDisponible = 0;

    const data = desgloceColores
      .map((a) => {
        totalMetros = totalMetros + a.cantidad;
        totalPedido = totalPedido + a.pedido;
        totalDisponible = totalDisponible + a.disponible;
          return [
            a.colores,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.pedido),
            new Intl.NumberFormat("en-US").format(a.disponible)
          ];
        }
      )
      
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Programacion ${articulosNombre}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Color",
          "Metros OC",
          "Pedido",
          "Disponible",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(totalMetros),
          new Intl.NumberFormat("en-US").format(totalPedido),
          new Intl.NumberFormat("en-US").format(totalDisponible),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Programacion-${articulosNombre}.pdf`);
  }

  function PDFTablaColoresDisp() {
    let totalDisponible = 0;

    const data = desgloceColores
      .map((a) => {
        totalDisponible = totalDisponible + a.disponible;
          return [
            a.colores,
            new Intl.NumberFormat("en-US").format(a.disponible)
          ];
        }
      )
      
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Programacion ${articulosNombre}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Color",
          "Disponible",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(totalDisponible),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Programacion-${articulosNombre}.pdf`);
  }


  function excelColores() {
    let totalMetros = 0;
    let totalPedido = 0;
    let totalDisponible = 0;

    const dataExcel = desgloceColores
      .map((a) => {
        totalMetros = totalMetros + a.cantidad;
        totalPedido = totalPedido + a.pedido;
        totalDisponible = totalDisponible + a.disponible;
          return {
            Color: a.colores,
            Cantidad: a.cantidad,
            Pedido: a.pedido,
            Disponible: a.disponible
          };
        }
      )
      
      const dataExportExcel = Object.values(dataExcel);
      const dataExcelLimpia = dataExportExcel.filter(function (el) {
        return el != null;
      });
  
      var option = {};
  
      option.fileName = "ReporteProgramacion";
  
      option.datas = [
        {
          sheetData: dataExcelLimpia,
          sheetName: "ReporteProgramacion",
          sheetFilter: [
            "Color",
            "Cantidad",
            "Pedido",
            "Disponible",
          ],
          sheetHeader: [
            "Color",
            "Cantidad",
            "Pedido",
            "Disponible",
          ],
        },
      ];
  
      var toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
  }

  async function jalaNota(id 
    // ,fecha, numero, subTotal, iva, total_general
    ) {
    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        setArticulosNota(allArticulosSurtido)
        toggleDesgloceArticulosPedidos()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  console.log("hola", articulosNota)

  let totalMetros = 0;
  let totalPedido = 0;
  let totalDisponible = 0;
  let totalPrecio = 0;
  let totalPrecioDisp = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_disponible ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuComprasInt"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
              <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTablaDisponible}
                >
                  Disp <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Reporte Programacion</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Reporte Programacion</h3>
          <div className="row">
            <div className="col-md-10">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-1 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
            <td></td>
            <td></td>
            <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedProveedor}
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre_comercial}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre} {a.codigo}</option>;
                    })}
                </Input>
              </td>
              
              <td style={{ paddingTop: "0px" }}></td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                totalMetros = totalMetros + a.cantidad;
                totalPedido = totalPedido + a.pedido;
                totalDisponible = totalDisponible + a.disponible;
                totalPrecio = totalPrecio + a.precioTotal
                totalPrecioDisp = totalPrecioDisp + a.precioDisponible
                {
                    return (
                      <tr style={{
                        ...(a.fechaCompromiso < hoy ? { backgroundColor: "#ed8c8c" } : {}),
                        ...(a.recibido > 0 ? { backgroundColor: "blue" } : {})
                    }}>
                    
                        <td>{a.noOrden}</td>
                        <td>{a.noOrdenProveedor}</td>
                        <td>{a.proveedor}</td>
                        <td>{a.articulo}</td>
                        <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                        <td>{new Intl.NumberFormat("en-US").format(a.pedido)}</td>
                        <td>{new Intl.NumberFormat("en-US").format(a.disponible)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tipoCambioEstimado)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precioTotal)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precioDisponible)}</td>
                        <td>{a.fechaOC}</td>
                        <td>{a.fechaCompromiso}</td>
                        <td>{a.fechaPuertoEst}</td>
                        <td>{a.fechaEntregaEst}</td>
                        <td>
                          {a.pedido > 0 ? (
                            <Button
                          color="success"
                          size="sm"
                          onClick={(e) =>
                            desglocePedido(
                              a.articulo,
                              a.idArticulo,
                              a.idOrden
                            )
                          }
                        >
                          <i class="fas fa-shopping-basket"></i>
                        </Button>
                          ) : (
                        <Button
                          color="success"
                          size="sm"
                          disabled
                        >
                          <i class="fas fa-shopping-basket"></i>
                        </Button>
                          )}
                        <Button
                          color="info"
                          size="sm"
                          onClick={(e) =>
                            desgloceColor(
                              a.articulo,
                              a.idArticulo,
                              a.idOrden
                            )
                          }
                        >
                          <i class="fas fa-palette"></i>
                        </Button>
                        </td>
                      </tr>
                    );

                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTALES</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalMetros)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalPedido)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalDisponible)}
                </td>      
                <td></td>
                <td></td>
                <td className="negrita">
                {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPrecio)}
                </td>
                <td className="negrita">
                {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPrecioDisp)}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}

<Modal size="xl" isOpen={modalDesglocePedidos} toggle={toggleDesglocePedidos}>
        <ModalHeader toggle={toggleDesglocePedidos}>
          <h4>Pedidos del Articulo: {articulosNombre}</h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped bordered className="table-responsive-xl">
            <tr>
              <th>Pedido</th>
              <th>Cliente</th>
              <th>Modelo</th>
              <th>Color</th>
              <th>Cantidad Pedido</th>
              <th>Pendiente Surtir</th>
              <th>Fecha Cancelacion</th>
              <th>Pedido Completo</th>
            </tr>
            <tbody>
              {desglocePedidos.map((a) => {
                return (
                  <tr>
                    <td>{a.pedido}</td>
                    <td>{a.cliente}</td>
                    <td>{a.observaciones}</td>
                    <td>{a.color}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(
                        a.pendiente_surtir
                      )}
                    </td>
                    <td>{a.fechaCompromiso}</td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={(e) => jalaNota(a.idPedido)}
                      >
                        <i class="fas fa-file-alt"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* </div> */}
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalDesgloceArticulosPedidos} toggle={toggleDesgloceArticulosPedidos}>
        <ModalHeader toggle={toggleDesgloceArticulosPedidos}>
          <h4>Pedidos del Articulo: {articulosNombre}</h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped bordered className="table-responsive-xl">
            <tr>
              <th>Modelo</th>
              <th>Color</th>
              <th>Cantidad Pedido</th>
              <th>Pendiente Surtir</th>
            </tr>
            <tbody>
              {articulosNota.map((a) => {
                return (
                  <tr>
                    <td>{a.articulos[0].nombre}</td>
                    <td>{a.colores[0].name}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.pendiente_surtir)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* </div> */}
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalDesgloceColores} toggle={toggleDesgloceColores}>
        <ModalHeader toggle={toggleDesgloceColores}>
          
          <h4>Colores del Articulo: {articulosNombre}</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            <Col sm={12}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTablaColoresDisp}
                >
                  Disp <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTablaColores}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelColores}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <br />
          <br />
          <Table size="sm" striped bordered className="table-responsive-xl">
            <tr>
              <th>Color</th>
              <th>Metros OC</th>
              <th>Pedido</th>
              <th>Disponible</th>
            </tr>
            <tbody>
              {desgloceColores.map((a) => {
                return (
                  <tr>
                    <td>{a.colores}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.pedido)}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.disponible)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* </div> */}
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ReporteProgramacion;
